import React from "react";
import SbEditable from "storyblok-react";
import Image from "./image";
import classnames from "classnames";
import styles from "./image.module.scss";
import { ImageStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: ImageStoryblok;
  className?: string;
  style?: React.CSSProperties;
}

const SbImage = (props: Props) => {
  const {
    blok: {
      image: { alt, filename, title, focus },
      aspect_ratio_mobile,
      aspect_ratio_desktop,
      border_radius = "",
      fitHeight,
    },
    className,
    style,
  } = props;

  const classes = classnames(
    styles.imageFigure,
    styles[border_radius],
    {
      [styles.fitHeight]: fitHeight,
    },
    className
  );

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      {filename !== "" ? (
        <figure className={classes} style={style}>
          <Image
            filename={filename}
            alt={alt}
            aspectMobile={aspect_ratio_mobile}
            aspectDesktop={aspect_ratio_desktop || aspect_ratio_mobile}
            focus={focus}
          />
          {title && (
            <figcaption className="ef-text-caption">{title}</figcaption>
          )}
        </figure>
      ) : (
        <h6 className="fallback">Please select an image.</h6>
      )}
    </SbEditable>
  );
};

export default SbImage;
